import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "/src/components/Layout";
import * as styles from "./index.module.css";
import buildingFront from "/src/images/photos/building-front.jpeg";
import chiropracticTable from "/src/images/photos/chiropractic-table.jpeg";
import massageTable from "/src/images/photos/massage-table.jpeg";
import waitingRoom from "/src/images/photos/waiting-room.jpeg";
import frontDoor from "/src/images/photos/front-door.jpeg";
import { SEO } from "/src/components/SEO";
import BookNow from "../components/BookNow";
import Hours from "../components/Hours";

const IndexPage = ({ path }) => {
  const { clinic } = useStaticQuery(graphql`
    query {
      clinic: graphCmsClinic {
        hours {
          closed
          dayOfWeek
          hours {
            close
            open
          }
        }
        address
        email
        phone
        name
        bookingUrl
      }
    }
  `);

  return (
    <Layout path={path}>
      <div className={styles.container}>
        <h1>Welcome</h1>
        <div style={{ maxWidth: 640 }}>
          <h2>
            Offering Chiropractic, Massage Therapy, and Natural Health &
            Wellness in Pacific Beach, CA
          </h2>
          <p>
            Personalized Chiropractic is a wellness center with providers
            specializing in chiropractic care, eastern medicine, massage therapy
            and functional nutrition. Founded in San Diego CA with the Southern
            California lifestyle in mind.
          </p>
          <p>
            Our mission at Personalized Chiropractic is to take care of your
            ailments efficiently and get you back to peak functional wellness:
            All while making you comfortable with your experience.
          </p>
          <p>
            Whether it be from pain, stiffness, loss of range of motion or other
            issues that can be treated individually or jointly with services in
            or out of the clinic. We use a combination of techniques to find the
            cause of the underlying imbalances affecting you to correct them at
            their source. Check out our full list of treatments & services, and
            check our team's broad range of experience.
          </p>
        </div>
        <p>
          Book your next{" "}
          <a
            href={clinic.bookingUrl}
            target="_blank"
          >
            appointment online
          </a>{" "}
          or by calling our office at{" "}
          <a
            href={`tel:${clinic.phone?.replaceAll(new RegExp(/\D/g), "")}`}
            style={{ whiteSpace: "nowrap" }}
          >
            {clinic.phone}
          </a>
          .
        </p>
        <p style={{ margin: "20px 0" }}>
          <Hours />
        </p>
        <p>
          <BookNow />
        </p>
      </div>
      <p className={styles.imageGrid}>
        <img src={waitingRoom} alt="" />
        <img
          style={{ gridArea: "1 / 1 / 3 / 1" }}
          src={chiropracticTable}
          alt=""
        />
        <img src={massageTable} alt="" />
        <img src={buildingFront} alt="" />
        <img src={frontDoor} alt="" />
      </p>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
